@tailwind base;
@tailwind components;
@tailwind utilities;

.apexcharts-legend {
  background: #f5f6f9;
  border-radius: 8px;
  height: fit-content;
  align-items: start;
  max-height: 200px;
}

.apexcharts-legend-series {
  line-height: 100% !important;
}

div.leaflet-overlay-pane svg {
  pointer-events: initial !important;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0) !important;
}

.apexcharts-tooltip {
  white-space: pre-wrap !important;
  max-width: 300px;
}

.apexcharts-tooltip-marker {
  min-width: 12px !important;
}

.apexcharts-legend-series {
  text-align: left !important;
}

@layer components {
  .scrollbar::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
}

.leaflet-container {
  width: 100%;
  height: 100%;
  min-height: max-content !important;
  background: transparent !important;
}

.leaflet-pane {
  width: 100%;
  height: 100%;
  z-index: 1 !important;
}

.leaflet-marker-pane {
  width: 0 !important;
  height: 0 !important;
}

.leaflet-shadow-pane {
  width: 0 !important;
  height: 0 !important;
}

.leaflet-tooltip-pane {
  width: 0 !important;
  height: 0 !important;
}

.leaflet-popup-pane {
  width: 0 !important;
  height: 0 !important;
}
